<template>
  <div class="row csn-user-account-section csn-ugr" id="user-account">
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>{{ t('title') }}</h1>
          <p>{{ t('description') }}</p>
        </section>

        <section class="csn-ugr-form-container">
          <h2 class="csn-ugr-form-title">{{ t('filter_game_rounds') }}</h2>
          <div>
            <Message
              :type="messageTypeEnum.FAILURE"
              :message="message"
              :hasBottom="true"
            />
            <Form class="form-inline" :ref="formRef">
              <div class="row-fluid">
                <DateField
                  :label="t('date_from')"
                  :name="name.DATE_FROM"
                  v-model="formData[name.DATE_FROM]"
                  :validators="[validator.required]"
                  :serverError="serverError[name.DATE_FROM]"
                />
                <DateField
                  :label="t('date_to')"
                  :name="name.DATE_TO"
                  v-model="formData[name.DATE_TO]"
                  :validators="[validator.required]"
                  :serverError="serverError[name.DATE_TO]"
                />
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn casino-btn-ver-1 casino-btn csn-form-inline-submit-btn casino-btn-theme-based"
                    :disabled="isSubmitting"
                    @click="submitForm"
                  >
                    <ButtonLoader v-if="isSubmitting" />
                    <span v-else>{{ t('bttn_label') }}</span>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </section>

        <section v-if="isSubmitting">
          <Loader />
        </section>

        <section v-if="rendersTable">
          <h2>{{ t('showing_results') }}</h2>
          <table>
            <tr>
              <th>{{ t('game_token') }}</th>
              <th>{{ t('time') }}</th>
              <th>{{ t('game') }}</th>
              <th>{{ t('balance') }}</th>
              <th>{{ t('bet') }}</th>
              <th>{{ t('win') }}</th>
            </tr>
            <tr v-for="(item, index) in list" :key="index">
              <td scope="row">
                {{ item.gameSessionId }}
              </td>
              <td scope="row" :title="item.start">
                {{ item.start }}
              </td>
              <td scope="row">
                {{ item.gameName }}
              </td>
              <td scope="row">
                {{ format({ number: item.startBalanceTotal }) }} /
                {{ format({ number: item.endBalanceTotal }) }}
              </td>
              <td scope="row">{{ format({ number: item.betTotal }) }}</td>
              <td scope="row">{{ format({ number: item.winTotal }) }}</td>
            </tr>
          </table>
          <div class="csn-dh-paginator-container">
            <Paginator v-model="pagination" @getData="getGameRounds" />
          </div>
        </section>

        <section v-if="isEmptyList">
          <p class="csn-user-date-empty-list">{{ t('no_game_rounds') }}</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { USER_GAME_ROUNDS } from '@/constants'
import { formatNumberLocale as format } from '@/helpers'
import { periodFormMixin } from '@/mixins'
import { AuthApi } from '@/api'

export default {
  name: USER_GAME_ROUNDS,
  mixins: [periodFormMixin],
  components: {
    Loader: () => import('@/components/Loader'),
    Form: () => import('@/components/FormData'),
    DateField: () => import('@/components/DateField'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    Paginator: () => import('@/components/Paginator'),
  },
  computed: {
    t() {
      return this.$createComponentTranslator(USER_GAME_ROUNDS)
    },
  },
  methods: {
    format,
    getGameRounds({ ...args }) {
      this.getData({ action: AuthApi.getGameRounds, args })
    },
    submitForm() {
      this.$refs[this.formRef].getValidation().isValid && this.getGameRounds()
    },
  },
  mounted() {
    this.getGameRounds()
  },
}
</script>
